import React from 'react';

import Layout from '../components/layout';
import Bluebanner from '../components/blue-banner';
import '../components/services.css';
import ContactBanner from '../components/contact-banner';
import Contact from '../components/contact';
import SEO from '../components/seo';

const Services = () => (
  <Layout>
    <SEO title="Services" />
    <Bluebanner
      source={require('../images/servicee.svg')}
      title="Our services"
      imgWidth={413.51}
      imgHeight="auto"
    ></Bluebanner>

    <section className="service-detail" id="web-dev">
      <div className="row">
        <div className="col span-1-of-2 boxx-service">
          <p className="service-detail-title">Web application development</p>
          <div className="serv-img-wrap-inv">
            <img
              className="service-web-inv-img"
              src={require('../images/comp.svg')}
              alt="Services"
            ></img>
          </div>
          <p className="service-para">
            The developers at Firstbyte are experts in crafting the web presence
            that your business deserves. We take pride in building beautiful and
            responsive websites that can drive conversions. We are also adept in
            building complex web applications.
          </p>
        </div>

        <div className="col span-1-of-2 boxx-service">
          <div className="serv-img-wrap">
            <img
              className="service-web"
              src={require('../images/comp.svg')}
              alt="Services"
            ></img>
          </div>
        </div>
      </div>

      <div id="mob-dev">
        <div className="row">
          <div className="col span-1-of-2 boxx-service">
            <div className="serv-img-wrap">
              <img
                className="service-mob"
                src={require('../images/mob-ppl.svg')}
                alt="Services"
              ></img>
            </div>
          </div>

          <div className="col span-1-of-2 boxx-service">
            <div className="nothing">
              <p className="service-detail-title">
                Mobile application development
              </p>
              <div className="serv-img-wrap-inv-div">
                <img
                  className="service-mob-inv-img"
                  src={require('../images/mob-ppl.svg')}
                  alt="Services"
                ></img>
              </div>
              <p className="service-para">
                We build mobile applications for Android and iOS platforms using
                React Native. Hiring the right team to build your mobile
                application will have an impact on the success of your business.
                We can help you mark your presence in the app ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row" id="cloud">
        <div className="col span-1-of-2 boxx-service">
          <p className="service-detail-title">Software consultancy</p>
          <div className="serv-img-wrap-inv">
            <img
              className="service-cloud-inv-img"
              src={require('../images/cloud-man.svg')}
              alt="Services"
            ></img>
          </div>
          <p className="service-para">
            Do you want to transform your business digitally? You think that
            technology will be your best ally to drive your business growth?
            There is no chance that you can be wrong. We will help you in
            digital enablement and digital transformation. We can guide you in
            arriving at the best decisions regarding the usage of software to
            support your business.
          </p>
        </div>

        <div className="col span-1-of-2 boxx-service">
          <div className="serv-img-wrap">
            {/* <img class="service-cloud" src="images/cloud-man.svg" width=547 height=auto> */}
            <img
              className="service-cloud"
              src={require('../images/cloud-man.svg')}
              alt="Services"
            ></img>
          </div>
        </div>
      </div>
    </section>
    <section className="contactt">
      <ContactBanner />
      <Contact />
    </section>
  </Layout>
);

export default Services;
